
import "./ModalInfoComponent.scss"
import IconClose from "../../../assets/icons/b01.svg";


function ModalInfoComponent({setShowInfo}) {
    return (
        <div className="modal_info">
            <div className="modal_info_window">
                <div className="modal_info_close">
                    <p className="gt_f3">Виды визуализации</p>
                    <button className="gt_icon_btn" onClick={()=>setShowInfo(false)}>
                        <img src={IconClose} alt="close modal"/>
                    </button>
                </div>
                <hr width="100%" color="#E4ECFD" size="1"/>
                <div className="modal_info_text">
                    <div style={{textAlign: "start"}}>
                        <span className="gt_tdesc">1. Аномальный рост</span>
                        <br/>
                        <span className="gt_pt">
                        Красным цветом помечаются регионы, в которых есть хотя бы один кластер с кратным
                        приростом обращаемости за период. Если в регионе есть несколько аномально растущих
                        кластеров, выбирается кластер с наибольшей динамикой для сравнения с другими
                        регионами
                    </span>
                    </div>
                </div>
                <div className="modal_info_text">
                    <div style={{textAlign: "start"}}>
                        <span className="gt_tdesc">2. Стабилизация</span>
                        <br/>
                        <span className="gt_pt">

Синим цветом помечаются регионы, в которых есть хотя бы один кластер с кратным снижением обращаемости за период. Если в регионе есть несколько кластеров с кратным снижением, выбирается кластер с наибольшей динамикой для сравнения с другими регионами
                    </span>
                    </div>
                </div>
                <div className="modal_info_text">
                    <div style={{textAlign: "start"}}>
                        <span className="gt_tdesc">3. Обращаемость</span>
                        <br/>
                        <span className="gt_pt">
                        Оранжевым цветом помечаются регионы, в которых есть обращения за выбранный период. Считаются все обращения в рамках региона, независимо от количества кластеров
                    </span>
                        <br/>
                        <br/>
                        <span className="gt_pt">
                            Чем больше динамика, чем больше обращений поступило по региону, тем ярче цвет. Серым цветом отображаются регионы без динамики, либо не подключенные к ПОС
                        </span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ModalInfoComponent;
