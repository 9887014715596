import "./BatchProcessing.scss"
import BackIcon from "../../../assets/icons/back_ar.svg"
import {useNavigate} from "react-router-dom";
import SelectGosteh from "../../SelectGosteh/SelectGosteh";
import {useEffect, useState} from "react";
import UploadAnnotation from "../../MyRoom/Administrator/CreateDataset/UploadAnnotation";
import {useDispatch, useSelector} from "react-redux";
import {getPetitionEventsAction, uploadPetitionAction} from "../../../store/actions/pipelinesAction";
import FileUploadProgress from "../../FileUploadProgress/FileUploadProgress";
import {ReactNotifications} from "react-notifications-component";


function BatchProcessing() {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const {uploadEvent,activateInterval} = useSelector(store=>store.folder)


    const options = ["Диагностический интерфейс автоматизированной кластеризации и суммаризации","Бинарный классификатор текста"]

    const [selectedOpt,setSelectedOpts] = useState("Бинарный классификатор текста")

    const [files, setFiles] = useState([])
    const [validFiles, setValidFiles] = useState(true)


    function handleSelect(opt) {
        setSelectedOpts(opt)
        setFiles([])
    }

    useEffect(()=>{
        if(!activateInterval) return;

        const interval = setInterval(() => {
            if (!uploadEvent) return;
            dispatch(getPetitionEventsAction(uploadEvent,selectedOpt === "Бинарный классификатор текста" ? "petition" : "smart_report"))
        }, 1000);

        return () => clearInterval(interval);
    },[activateInterval,uploadEvent,selectedOpt])


    async function handleStartProcessing(){

        async function convertToBase64Async(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        }

        const base64 = await convertToBase64Async(files[0]);

        if (selectedOpt === "Бинарный классификатор текста"){
            let data = {
                "petition_col_name": "Вопрос",
                "answer_col_name": "Ответ",
                "file": base64.replace("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,", ""),
            }
            dispatch(uploadPetitionAction(data,"petition"))
        }else {
            let data = {
                "file": base64.replace("data:text/csv;base64,", ""),
            }
            dispatch(uploadPetitionAction(data,"smart_report"))
        }


    }
    return (
        <div className="bach_processing">
            <ReactNotifications />
            <div className="bach_processing_content">
                <button type="button" className="back_btn" onClick={() => navigate("/pipeline")}>
                    <img src={BackIcon} alt="back"/>
                    <p className="gt_pt gt_gb">Назад</p>
                </button>
                <p className="gt_f3">Пакетная обработка</p>
                <div className="bach_settings">
                    <div className="bach_settings_item">
                        <p className="gt_ps">Функция обработки</p>
                        <SelectGosteh options={options} selectChange={handleSelect}/>
                    </div>
                    <div className="bach_settings_item">
                        <p className="gt_ps">Данные для обработки</p>
                        <UploadAnnotation files={files} setFiles={setFiles} setValidFiles={setValidFiles}
                                          accept_formats={selectedOpt === "Бинарный классификатор текста" ? ["xlsx"] : ["csv"]} max_count={1}/>
                    </div>
                    <div className="btn_block">
                        <button type="button" className="gt_btn_primary start_btn" disabled={files.length === 0 || !selectedOpt || activateInterval}
                        onClick={handleStartProcessing}>
                            <p className="gt_pt">Запустить обработку</p>
                        </button>
                    </div>

                </div>

            </div>
            <FileUploadProgress/>
        </div>
    )
}

export default BatchProcessing;
