import "./Menu.scss";
import Arrow from "../../assets/icons/b14.svg"
import Home from "../../assets/icons/d01.svg";
import Database from "../../assets/icons/Database.svg";
import Piplene from "../../assets/icons/pipelines.svg";

import {useState} from "react";
import {useNavigate} from "react-router-dom";

function Menu () {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    return (
        <div>
            <div className="menu_close">
                <button type="button" className="menu_close_arrow_btn" onClick={() => setOpen(true)}>
                    <img src={Arrow} alt="arrow"/>
                </button>
                <button type="button" className="menu_close_item" onClick={()=> navigate("/my_room_admin")}>
                    <img src={Home} alt="home"/>
                </button>
                <button type="button" className="menu_close_item">
                    <img src={Database} alt="arrow"/>
                </button>
                <button type="button" className="menu_close_item" onClick={()=> navigate("/pipeline")}>
                    <img src={Piplene} alt="pipe"/>
                </button>
            </div>
            <div  className="menu_open" style={{left: open ? "0" : "-300px"}}>
                <button type="button" className="menu_open_arrow_btn" onClick={() => setOpen(false)}>
                    <img src={Arrow} alt="arrow" className="menu_open_arrow_back"/>
                </button>
                <button type="button" className="menu_open_item" onClick={()=> navigate("/my_room_admin")}>
                    <img src={Home} alt="home"/>
                    <p className="gt_pt">Моя комната</p>
                </button>
                <button type="button" className="menu_open_item">
                    <img src={Database} alt="arrow"/>
                    <p className="gt_pt">Опубликованные датасеты</p>
                </button>
                <button type="button" className="menu_open_item" onClick={()=> navigate("/pipeline")}>
                    <img src={Piplene} alt="pipe"/>
                    <p className="gt_pt">Потоковая обработка</p>
                </button>
            </div>
        </div>

    )
}

export default Menu;
