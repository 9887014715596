import { apiConfig } from '../../utils/apiConfig'
import axios from "axios";
import {addLogsAction} from "../reducers/appReducer";
import {
    addClustersAction,
    addPetitionAction,
    setChartDataAction,
    setClustersAction,
    setCurrentClusterAction,
    setGroupsAction, setLoadingChartsAction,
    setLoadingClustersAction, setLoadingGroupsAction, setLoadingPetitionsAction,
    setLoadingScrollClustersAction, setLoadingScrollPetitionsAction,
    setPetitionAction
} from "../reducers/smartReportReducer";
import authHeader from "./userAction";


export const getClustersAction = (settings,add = false,map_mode = false) => {
    return async (dispatch) => {
        try {

            if (!add){
                dispatch(setLoadingClustersAction(true))
            }else {
                dispatch(setLoadingScrollClustersAction(true))
            }
           const response = await axios.post('smart-report/poc_smart/get-clusters',
                settings,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
            if (!add){
                dispatch(setClustersAction([]))
                dispatch(setClustersAction(response.data.data))
                dispatch(setCurrentClusterAction(response.data.data[0].id))
            }else {
                dispatch(addClustersAction(response.data.data))
            }

            if (map_mode){
                dispatch(setPetitionAction([]))
                dispatch(setCurrentClusterAction(response.data.data[0].id))
            }
            dispatch(setLoadingClustersAction(false))
            dispatch(setLoadingScrollClustersAction(false))

        } catch (e) {
            dispatch(setLoadingClustersAction(false))
            dispatch(setLoadingScrollClustersAction(false))
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getPetitionsAction = (settings,add = false) => {
    return async (dispatch) => {
        try {
            if (add){
                dispatch(setLoadingScrollPetitionsAction(true))
            }else {
                dispatch(setLoadingPetitionsAction(true))
            }
            const response = await axios.post('smart-report/poc_smart/get-petitions',
                settings,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
            if (!add){
                dispatch(setPetitionAction(response.data[0].data))
            }else {
                dispatch(addPetitionAction(response.data[0].data))
            }
            dispatch(setLoadingPetitionsAction(false))
            dispatch(setLoadingScrollPetitionsAction(false))
        } catch (e) {
            dispatch(setLoadingPetitionsAction(false))
            dispatch(setLoadingScrollPetitionsAction(false))
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const getGroupedAction = (settings) => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingGroupsAction(true))
            dispatch(setGroupsAction([]))
            const response = await axios.post('smart-report/poc_smart/grouped',
                settings,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
            dispatch(setGroupsAction(response.data.data))
            dispatch(setLoadingGroupsAction(false))
        } catch (e) {
            dispatch(setLoadingGroupsAction(false))
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getChartDataAction = (settings) => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingChartsAction(true))
            const response = await axios.post('smart-report/poc_smart/get-chart-data',
                settings,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
            dispatch(setChartDataAction(response.data.data))
            dispatch(setLoadingChartsAction(false))
        } catch (e) {
            dispatch(setLoadingChartsAction(false))
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

