import "./RegionsMapComponent.scss"
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getClustersAction} from "../../../store/actions/smartReportAdction";
import {setMapStyle} from "../helpers";
import MapButtomAg from "../../../assets/icons/map_buttom_ag.svg"
import MapButtomSt from "../../../assets/icons/map_buttom_st.svg"
import MapButtomAp from "../../../assets/icons/map_buttom_ap.svg"
import EmptyMapComponent from "../EmptyMapComponent/EmptyMapComponent";


function RegionsMapComponent({sortShow,setShowReport,setGroupBy,setTitle}) {

    const {groups} = useSelector(store=>store.smartReport)
    const dispatch = useDispatch()


    useEffect(()=>{

        groups.forEach(group => {
            let el = document.getElementById(group.sn)

            const handleClick = event => {
                setShowReport(true)
                let settings = JSON.parse(localStorage.getItem("settings_cluster"))
                settings.size = 15

                settings.filter_map = event.target.id
                settings.group_by = "region"
                dispatch(getClustersAction(settings,false,true))
                setGroupBy("регионы")
                localStorage.setItem("map_settings",JSON.stringify({
                    type:"region",
                    name: event.target.id
                }));

                setTitle(groups.filter(item => item.sn === event.target.id)[0].n)
            };

            if (el){
                setMapStyle(el,group.le,sortShow)
                el.addEventListener('click', handleClick)
            }

        })

    },[groups])

    return (
        <div className="regions_map">
            <p className="gt_h4">Регионы РФ</p>

            {
                groups.filter(gr => gr.le > 0).length > 0 ?
                    <>
                        <div className="parent">
                            <button type="button" className="div1" id="КАР">КАР</button>
                            <button type="button" className="div2" id="МУР">МУР</button>
                            <button type="button" className="div3" id="СПБ">СПБ</button>
                            <button type="button" className="div4" id="ЛЕН">ЛЕН</button>
                            <button type="button" className="div5" id="НОВ">НОВ</button>
                            <button type="button" className="div6" id="ВОЛ">ВОЛ</button>
                            <button type="button" className="div7" id="АРХ">АРХ</button>
                            <button type="button" className="div8" id="НЕН">НЕН</button>
                            <button type="button" className="div9" id="КОМ">КОМ</button>
                            <button type="button" className="div10" id="КАГ">КАГ</button>
                            <button type="button" className="div11" id="ПСК">ПСК</button>
                            <button type="button" className="div12" id="ТВЕ">ТВЕ</button>
                            <button type="button" className="div13" id="ЯРО">ЯРО</button>
                            <button type="button" className="div14" id="КОС">КОС</button>
                            <button type="button" className="div15" id="КИР">КИР</button>
                            <button type="button" className="div16" id="ПЕР">ПЕР</button>
                            <button type="button" className="div17" id="ХАН">ХАН</button>
                            <button type="button" className="div18" id="ЯМА">ЯМА</button>
                            <button type="button" className="div27" id="ЧУК">ЧУК</button>
                            <button type="button" className="div28" id="КАМ">КАМ</button>
                            <button type="button" className="div19" id="СМО">СМО</button>
                            <button type="button" className="div20" id="МОС">МОС</button>
                            <button type="button" className="div21" id="ИВА">ИВА</button>
                            <button type="button" className="div22" id="НИЖ">НИЖ</button>
                            <button type="button" className="div23" id="МАР">МАР</button>
                            <button type="button" className="div24" id="УДМ">УДМ</button>
                            <button type="button" className="div25" id="СВЕ">СВЕ</button>
                            <button type="button" className="div26" id="ТЮМ">ТЮМ</button>
                            <button type="button" className="div29" id="ТОМ">ТОМ</button>
                            <button type="button" className="div30" id="КРН">КРН</button>
                            <button type="button" className="div31" id="ЯКУ">ЯКУ</button>
                            <button type="button" className="div32" id="МАГ">МАГ</button>
                            <button type="button" className="div33" id="БРЯ">БРЯ</button>
                            <button type="button" className="div34" id="КАЛ">КАЛ</button>
                            <button type="button" className="div35" id="МОЯ">МОЯ</button>
                            <button type="button" className="div36" id="ВЛА">ВЛА</button>
                            <button type="button" className="div37" id="МОР">МОР</button>
                            <button type="button" className="div38" id="ЧУВ">ЧУВ</button>
                            <button type="button" className="div39" id="ТАТ">ТАТ</button>
                            <button type="button" className="div40" id="КУР">КУР</button>
                            <button type="button" className="div41" id="ОМС">ОМС</button>
                            <button type="button" className="div42" id="НОС">НОС</button>
                            <button type="button" className="div43" id="КЕМ">КЕМ</button>
                            <button type="button" className="div44" id="ИРК">ИРК</button>
                            <button type="button" className="div45" id="АМУ">АМУ</button>
                            <button type="button" className="div88" id="ХАБ">ХАБ</button>
                            <button type="button" className="div46" id="САХ">САХ</button>

                            <button type="button" className="div47" id="КУС">КУС</button>
                            <button type="button" className="div48" id="ОРЛ">ОРЛ</button>
                            <button type="button" className="div49" id="ТУЛ">ТУЛ</button>
                            <button type="button" className="div50" id="РЯЗ">РЯЗ</button>
                            <button type="button" className="div51" id="ПЕН">ПЕН</button>
                            <button type="button" className="div52" id="УЛЬ">УЛЬ</button>
                            <button type="button" className="div53" id="БАШ">БАШ</button>
                            <button type="button" className="div54" id="ЧЕЛ">ЧЕЛ</button>
                            <button type="button" className="div55" id="АЛР">АЛР</button>
                            <button type="button" className="div56" id="ХАК">ХАК</button>
                            <button type="button" className="div57" id="БУР">БУР</button>
                            <button type="button" className="div58" id="ЗАБ">ЗАБ</button>
                            <button type="button" className="div89" id="ЕВР">ЕВР</button>
                            <button type="button" className="div59" id="БЕЛ">БЕЛ</button>

                            <button type="button" className="div60" id="ЛИП">ЛИП</button>
                            <button type="button" className="div61" id="ТАМ">ТАМ</button>
                            <button type="button" className="div62" id="САМ">САМ</button>
                            <button type="button" className="div63" id="САР">САР</button>
                            <button type="button" className="div64" id="ОРЕ">ОРЕ</button>
                            <button type="button" className="div65" id="АЛТ">АЛТ</button>
                            <button type="button" className="div66" id="ТУВ">ТУВ</button>
                            <button type="button" className="div67" id="ПРИ">ПРИ</button>
                            <button type="button" className="div68" id="ХСН">ХСН</button>
                            <button type="button" className="div69" id="ЗАП">ЗАП</button>
                            <button type="button" className="div70" id="ДНР">ДНР</button>
                            <button type="button" className="div71" id="ЛНР">ЛНР</button>
                            <button type="button" className="div72" id="ВОР">ВОР</button>
                            <button type="button" className="div73" id="ВОГ">ВОГ</button>
                            <button type="button" className="div74" id="СВС">СВС</button>
                            <button type="button" className="div75" id="КРМ">КРМ</button>
                            <button type="button" className="div76" id="КРА">КРА</button>
                            <button type="button" className="div77" id="РОС">РОС</button>
                            <button type="button" className="div78" id="КАИ">КАИ</button>
                            <button type="button" className="div79" id="АСТ">АСТ</button>
                            <button type="button" className="div80" id="АДЫ">АДЫ</button>
                            <button type="button" className="div81" id="СТА">СТА</button>
                            <button type="button" className="div82" id="ЧЕЧ">ЧЕЧ</button>
                            <button type="button" className="div83" id="ДАГ">ДАГ</button>
                            <button type="button" className="div84" id="КАО">КАО</button>
                            <button type="button" className="div85" id="КАБ">КАБ</button>
                            <button type="button" className="div86" id="ИНГ">ИНГ</button>
                            <button type="button" className="div87" id="СЕВ">СЕВ</button>
                        </div>

                        <img src={
                            sortShow === "аномальный рост" ? MapButtomAg :
                                sortShow === "стабилизация" ? MapButtomSt : MapButtomAp
                        } alt="footer"/>
                    </> :
                    <EmptyMapComponent />
            }


        </div>
    )
}

export default RegionsMapComponent;
