import "./PetitionComponent.scss"
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {getChartDataAction, getPetitionsAction} from "../../../store/actions/smartReportAdction";

import {
    ComposedChart,
    Line,
    Bar,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer, XAxis,
} from 'recharts';
import {useEffect} from "react";
import {setPetitionAction} from "../../../store/reducers/smartReportReducer";
import LoadSpinner from "../../LoadSpinner/LoadSpinner";
import {getDateTime} from "../helpers";
import CustomTooltipComponent from "../CustomTooltipComponent/CustomTooltipComponent";

function PetitionComponent() {


    const {petitions,currentCluster,chartData,clusters,loadingScrollPetitions,loadingCharts,loadingPetitions} = useSelector(store=>store.smartReport)
    const dispatch = useDispatch()

    useEffect(() => {

        const cluster_settings = JSON.parse(localStorage.getItem("settings_cluster"))

        let settings = {
            "id": currentCluster,
            "page": 0,
            "size": 25,
            "token": "dsadasdsad1",
            "dt_from": cluster_settings.dt_from,
            "dt_to": cluster_settings.dt_to,
            "only_new": cluster_settings.only_new,
            "group_by": cluster_settings.group_by,
        }

        dispatch(setPetitionAction([]))
        dispatch(getPetitionsAction(settings))

        let chart_settings = {
            "id": currentCluster,
            "dt_from": cluster_settings.dt_from,
            "dt_to": cluster_settings.dt_to,
            "only_new": cluster_settings.only_new,
            "count": 24,
            "token": "9769b4ec-9b96-4d84-9c7d-8b8c96e0a1da-token"
        }
        chart_settings.group_by = "all"

        dispatch(getChartDataAction(chart_settings))

    }, [currentCluster]);


    function getDataScroll(){
        if (!loadingScrollPetitions){
            if (Number.isInteger(petitions.length / 25)){
                let page = Math.floor(petitions.length / 25)

                const cluster_settings = JSON.parse(localStorage.getItem("settings_cluster"))

                let settings = {
                    "id": currentCluster,
                    "page": page,
                    "size": 25,
                    "token": "string2",
                    "dt_from": cluster_settings.dt_from,
                    "dt_to": cluster_settings.dt_to,
                    "only_new": cluster_settings.only_new,
                    "group_by": cluster_settings.group_by,
                }

                dispatch(getPetitionsAction(settings,true))
            }
        }
    }



    return (
        clusters.length > 0 ?
        <div className="petition">
            <div style={{position:"relative"}}>
                {
                    loadingCharts ?
                        <div className="loader_conteiner">
                            <LoadSpinner />
                        </div> : " "
                }

                    <ResponsiveContainer width="100%"
                                         height={200}
                    >
                        <ComposedChart
                            width={500}
                            height={200}
                            data={chartData}
                            margin={{
                                top: 20,
                                right: 20,
                                bottom: 5,
                                left: 20,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5"/>
                            <YAxis tick={{fill: "#9E9FA1"}} stroke="#9E9FA1"/>
                            <XAxis tick={{fill: "#9E9FA1"}} stroke="#9E9FA1"/>
                            <Tooltip content={<CustomTooltipComponent />} />
                            <Bar dataKey="mc" fill="#B2CAEB"/>
                            <Line type="monotone" dataKey="k" stroke="#E11432"/>
                        </ComposedChart>
                    </ResponsiveContainer>

                    <div className="legend_block">
                        <div className="legend_item">
                            <div className="legend_count"></div>
                            <p className="gt_ps">число обращений</p>
                        </div>
                        <div className="legend_item">
                            <div className="legend_up"></div>
                            <p className="gt_ps">коэффициент прироста</p>
                        </div>
                    </div>
            </div>

            <hr width="100%" color="#E4ECFD" size="1" style={{marginBottom:"15px"}}/>
            {
                loadingPetitions ?
                    <div className="petitions_loading">
                        <LoadSpinner/>
                    </div> :
                    <InfiniteScroll
                        dataLength={petitions.length}
                        next={getDataScroll}
                        hasMore={true}
                        height={600}
                    >
                        {petitions.map((petition, index) => (
                            <div className="petition_item" key={petition.id+"-"+currentCluster}>
                                <div className="petition_item_info">
                                    <p className="gt_pb" style={{color:"#7995CF"}}>
                                        {index < 10 ? "00"+ (index+1) : index < 100 ? "0"+ (index+1)  : (index+1)}
                                    </p>
                                    <p className="gt_ps gt_th">
                                        {getDateTime(petition.reg_date)}
                                    </p>
                                </div>
                                <p className="gt_pt">{petition.text}</p>
                            </div>
                        ))}
                    </InfiniteScroll>
            }

            {
                loadingScrollPetitions ?
                    <div className="scroll_loading">
                        <LoadSpinner withSvg={"24px"}/>
                    </div> : ""
            }
        </div> : ""
    )
}

export default PetitionComponent;
