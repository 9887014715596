import "./CalendarComponent.scss"
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';
import {CustomProvider, Stack} from 'rsuite';
import ruRu from 'rsuite/locales/ru_RU';
import {useEffect, useState} from "react";
import {ISODateString} from "../helpers";
import { startOfDay, endOfDay, addDays, subDays,subMonths,subYears } from 'date-fns';


function CalendarIcon(){
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon_Time, place/calendar">
                <path id="Union" fill-rule="evenodd" clip-rule="evenodd"
                      d="M16.25 1.19727C16.6642 1.19727 17 1.53305 17 1.94727V3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3H7V1.94727C7 1.53305 7.33579 1.19727 7.75 1.19727C8.16421 1.19727 8.5 1.53305 8.5 1.94727V3L15.5 3V1.94727C15.5 1.53305 15.8358 1.19727 16.25 1.19727ZM7 4.5V5.44727C7 5.86148 7.33579 6.19727 7.75 6.19727C8.16421 6.19727 8.5 5.86148 8.5 5.44727V4.5L15.5 4.5V5.44727C15.5 5.86148 15.8358 6.19727 16.25 6.19727C16.6642 6.19727 17 5.86148 17 5.44727V4.5H18C18.8284 4.5 19.5 5.17157 19.5 6V8.01562L4.5 8.01562V6C4.5 5.17157 5.17157 4.5 6 4.5H7ZM4.5 9.51562L4.5 18C4.5 18.8284 5.17157 19.5 6 19.5H18C18.8284 19.5 19.5 18.8284 19.5 18V9.51562L4.5 9.51562Z"
                      fill="#9DACCE"/>
            </g>
        </svg>

    )
}

function CalendarComponent({setPeriod}){

    ruRu.DateRangePicker.formattedMonthPattern = "LLLL yyyy"
    ruRu.DateRangePicker.ok = "Сохранить"

    const Ranges = [
        {
            label: 'Сегодня',
            value: [startOfDay(new Date()), endOfDay(new Date())]
        },
        {
            label: 'Вчера',
            value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
        },
        {
            label: 'Неделя',
            value: [startOfDay(addDays(new Date(), -6)), endOfDay(new Date())]
        },
        {
            label: 'Месяц',
            value: [startOfDay(subMonths(new Date(), 1)), endOfDay(new Date())]
        },
        {
            label: 'Год',
            value: [startOfDay(subYears(new Date(), 1)), endOfDay(new Date())]
        }
    ];


    const [value, setValue] = useState([])

    useEffect(()=>{
        let date_delta = new Date()
        date_delta.setDate(date_delta.getDate() - 30)

        setPeriod([ISODateString(date_delta,"from"),ISODateString(new Date(),"to")])
        localStorage.setItem("period",JSON.stringify([ISODateString(date_delta,"from"),ISODateString(new Date(),"to")]));
        setValue([
            date_delta,
            new Date()
        ])
    },[])


    function handleClose(){
        if (value.length > 0) {
            setPeriod([ISODateString(value[0],"from"),ISODateString(value[1],"to")])
            localStorage.setItem("period",JSON.stringify([ISODateString(value[0],"from"),ISODateString(value[1],"to")]));
        }
    }

    return (
        <CustomProvider locale={ruRu}>
            <DateRangePicker isoWeek ranges={[]}
                             character=" – "
                             showHeader={false}
                             value={value}
                             onChange={setValue}
                             onExit={handleClose}
                             cleanable={false}
                             ranges={Ranges}
                             caretAs={CalendarIcon}
                             placement='bottomEnd'
            />
        </CustomProvider>
    )
}

export default CalendarComponent;
