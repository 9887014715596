import "./ClustersList.scss"
import IconGeo from "../../../assets/icons/e09.svg";
import IconGerb from "../../../assets/icons/x02.svg";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentClusterAction, setPetitionAction} from "../../../store/reducers/smartReportReducer";
import {getClustersAction} from "../../../store/actions/smartReportAdction";
import InfiniteScroll from "react-infinite-scroll-component";
import {getClustersRequestSettings} from "../helpers";
import LoadSpinner from "../../LoadSpinner/LoadSpinner";


function ClustersList({showReport,setShowReport,period,groupBy,setGroupBy,sortBy,checkedNew,sortDir,setTitle,title}) {
    const dispatch = useDispatch()
    const {clusters,currentCluster,loadingScrollClusters} = useSelector(store=>store.smartReport)

    function handleSelectCluster (cluster){

        if (showReport) {
            if (currentCluster !== cluster.id){
                dispatch(setPetitionAction([]))
                dispatch(setCurrentClusterAction(cluster.id))
            }
        }
        else {
            dispatch(setCurrentClusterAction(cluster.id))
            setShowReport(true)

            const settings = JSON.parse(localStorage.getItem("settings_cluster"))
            settings.size = 15
            dispatch(getClustersAction(settings))

            setTitle("Все кластеры")
        }

    }

    function getDataScroll(){
        if (!loadingScrollClusters) {
            if (Number.isInteger(clusters.length / 15)) {
                let page = Math.floor(clusters.length / 15)

                let settings = getClustersRequestSettings(period[0],period[1],
                    checkedNew,groupBy,
                    sortBy,sortDir,
                    page,15)

                const map_settings = JSON.parse(localStorage.getItem("map_settings"))
                if (map_settings) {
                    settings.filter_map = map_settings.name
                    settings.group_by = map_settings.type
                }

                dispatch(getClustersAction(settings,true))
            }
        }
    }

    function convertString(val){
        const res_str = Math.round(val) > 9999 ? "∞" :  (Number(val) > 1 || Number(val) < 1) && Math.round(val)? Math.round(val) + "%"
            : Number(val) > -0.1 && Number(val) < 0.1 ? "-" : val.toFixed(1) + "%"

        return res_str
    }

    function handleRegFoivClick(e,item){
        if (showReport && title === item.tn) {
            return
        }
        e.stopPropagation()
        setShowReport(true)
        let settings = JSON.parse(localStorage.getItem("settings_cluster"))
        settings.size = 15
        settings.filter_map = item.fv
        settings.group_by = item.ct
        setGroupBy(item.ct === "foiv" ? "ОГВ" : "регионы")
        dispatch(getClustersAction(settings,false,true))

        localStorage.setItem("map_settings",JSON.stringify({
            type:"foiv",
            name: item.fv
        }));

        setTitle(item.tn)

    }

    return (
        <div className="clusters_list" id="clusters_list">

                <InfiniteScroll
                    dataLength={clusters.length}
                    next={getDataScroll}
                    hasMore={true}
                    height={showReport ? 880 : 690}
                >
                    {
                        clusters.map((item,i) => (
                            <button type="button"
                                    className={`clusters_list_item ${currentCluster === item.id && showReport ? 'selected_item' : ''}`}
                                    key={item.id}
                                    onClick={() => handleSelectCluster(item)}>

                                <div className="main_block">
                                    <p className="gt_h5 cluster_title">
                                        {i+1+". "+item.cn}
                                    </p>

                                    <div className="stats_block">
                                        <div style={{display: "flex", gap: "8px", alignItems: "end"}} >
                                            <img src={item.ct === "foiv" ? IconGerb :IconGeo} alt="geo" onClick={(e) => handleRegFoivClick(e,item)}/>
                                            <p className="gt_pt gt_th" style={{width:"270px",textAlign:"start"}} onClick={(e) => handleRegFoivClick(e,item)}>
                                                {item.tn}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="gt_h4">
                                                {convertString(item.sp)}
                                            </p>
                                            <p className="gt_ps gt_th" style={{width: "100px"}}>за период</p>
                                        </div>
                                        <div>
                                            <p className="gt_h4">
                                                {convertString(item.sr)}
                                            </p>
                                            <p className="gt_ps gt_th" style={{width: "145px"}}>динамика за интервал</p>
                                        </div>
                                        <div>
                                            <p className="gt_h4">
                                                {convertString(item.sf)}
                                            </p>
                                            <p className="gt_ps gt_th" style={{width: "105px"}}>доля от всех</p>
                                        </div>
                                    </div>
                                </div>

                                {
                                    item.in  ?
                                        <div className="new_cont">
                                            <div className="new_block">
                                                <p className="gt_ps">НОВЫЙ</p>
                                            </div>
                                        </div> : ""
                                }


                            </button>
                        ))
                    }
                </InfiniteScroll>
            {
                loadingScrollClusters ?
                    <div className="scroll_loading">
                        <LoadSpinner withSvg={"24px"}/>
                    </div> : ""
            }
        </div>
    )
}

export default ClustersList;
