import "./ClustersComponent.scss"
import ReactSwitch from "react-switch";
import {useEffect, useState} from "react";
import ClustersList from "../ClustersList/ClustersList";
import IconArrowBot from "../../../assets/icons/b15_b.svg"
import IconLine from "../../../assets/icons/Line397.svg"
import IconArrowDescUp from "../../../assets/icons/a02.svg"
import IconEmpty from "../../../assets/icons/empty_image.svg"
import {useDispatch, useSelector} from "react-redux";
import {getChartDataAction, getClustersAction} from "../../../store/actions/smartReportAdction";
import {setCurrentClusterAction} from "../../../store/reducers/smartReportReducer";
import {getClustersRequestSettings} from "../helpers";
import SortSelect from "../SortSelect/SortSelect";
import LoadSpinner from "../../LoadSpinner/LoadSpinner";

function ClustersComponent({showReport,setShowReport,period,setGroupBy,groupBy,checkedNew,setCheckedNew,setTitle,title}){
    const dispatch = useDispatch()
    const {clusters,loadingClusters} = useSelector(store=>store.smartReport)

    const handleChange = (nextChecked) => {
        setCheckedNew(nextChecked);
    };


    const [sortBy,setSortBy] = useState("За период");
    const [sortDir,setSortDir] = useState(true);

    const sorts= ["За период","Доля от всех","Динамика за интервал"]


    const [showOpt,setShowOpt] = useState(false);

    useEffect(()=>{
        if (period.length > 0){
            const map_settings = JSON.parse(localStorage.getItem("map_settings"))

            let settings = getClustersRequestSettings(period[0],period[1],
                checkedNew,groupBy,
                sortBy,sortDir,
                0,showReport ? 15 : 5)

            if(map_settings){
                settings.group_by = map_settings.type
                settings.filter_map =  map_settings.name
            }

            dispatch(getClustersAction(settings))

            localStorage.setItem("settings_cluster",JSON.stringify(settings));

            if (showReport){

                let chart_settings = {
                    "id": map_settings ? map_settings.name : "",
                    "dt_from": settings.dt_from,
                    "dt_to": settings.dt_to,
                    "only_new": settings.only_new,
                    "count": 24,
                    "token": "9769b4ec-9b96-4d84-9c7d-8b8c96e0a1da-token"
                }
                if (map_settings){
                    if (map_settings.type === "region"){
                        chart_settings.group_by = "region"
                    }else {
                        chart_settings.group_by = "foiv"
                    }
                }else {
                    chart_settings.group_by =  settings.group_by
                }
                dispatch(getChartDataAction(chart_settings))
            }
        }

    },[period,groupBy,sortBy,sortDir,checkedNew])


    function handleShowAll() {
        if (clusters.length > 0){
            dispatch(setCurrentClusterAction(clusters[0].id))

            let settings2 = getClustersRequestSettings(period[0],period[1],
                checkedNew,groupBy,
                sortBy,sortDir,
                0,15)

            dispatch(getClustersAction(settings2))

            //let settings = getPetitionsRequestSettings(period[0],period[1],checkedNew,groupBy,clusters[0].id,0,25)

            //dispatch(getPetitionsAction(settings))

            setShowReport(true)

            localStorage.setItem("all_clusters","Все кластеры");
            setTitle("Все кластеры")
        }
    }


    return (
        <div className="clusters">


            <div className="clusters_filters">
                <div className="clusters_filters_item">
                    <p className="gt_pt">
                        Только новые
                    </p>
                    <ReactSwitch
                        onChange={handleChange}
                        checked={checkedNew}
                        className="react-switch"
                        onColor="#042F89"
                        uncheckedIcon={false}
                        checkedIcon={false}
                    />
                </div>

                <div className="clusters_filters_item">
                    <div className="select_filter ">
                        <button type="button"
                                className={`select_item ${groupBy === "регионы" ? 'selected' : ''} `}
                                onClick={() => setGroupBy("регионы")}
                                disabled={localStorage.getItem("map_settings")}
                        >
                            <p className={groupBy === "регионы" ? 'gt_pb' : 'gt_ps'}>Регионы</p>
                        </button>
                        <button type="button"
                                className={`select_item ${groupBy === "ОГВ" ? 'selected' : ''} `}
                                onClick={() => setGroupBy("ОГВ")}
                                disabled={localStorage.getItem("map_settings")}
                        >
                            <p className={groupBy === "ОГВ" ? 'gt_pb' : 'gt_ps'}>ОГВ</p>
                        </button>
                    </div>
                </div>

                <div className="clusters_filters_item">
                    <p className="gt_pt">
                        Сортировка:
                    </p>
                    <div>
                        <div className="sort_button">
                            <button type="button" className="sort_by_btn" onClick={()=>setShowOpt(!showOpt)}>
                                <p className="gt_ps">{sortBy}</p>
                                <img src={IconArrowBot} alt="bot arrow" style={showOpt ? {transform: 'rotate(180deg)'} : {}} />
                            </button>
                            <img src={IconLine} alt="line"/>
                            <button type="button" style={{height:"24px"}}>
                                <img src={IconArrowDescUp}
                                     alt="desc-up"
                                     style={!sortDir ? {transform: 'rotate(180deg)'} : {}}
                                     onClick={()=>setSortDir(!sortDir)}
                                />
                            </button>
                        </div>
                        <SortSelect value={sortBy} options={sorts} selectChange={(opt) => setSortBy(opt)} showOpt={showOpt} setShowOpt={setShowOpt}/>
                    </div>
                </div>

            </div>


            <div style={{position:"relative"}}>
                {
                    loadingClusters ?
                        <div className="loader_conteiner">
                            <LoadSpinner />
                        </div> : " "
                }
                {
                    clusters.length > 0  ?
                        <>
                            <ClustersList setShowReport={setShowReport} showReport={showReport} period={period} groupBy={groupBy}
                                          setGroupBy={setGroupBy} sortBy={sortBy} checkedNew={checkedNew} sortDir={sortDir}
                                          setTitle={setTitle} title={title}/>
                            {
                                !showReport ?
                                    <button type="button" style={{width: '200px'}} onClick={handleShowAll}>
                                        <p className="gt_pt gt_gb">
                                            Посмотреть все кластеры
                                        </p>
                                    </button> : ""
                            }
                        </>
                        :
                        <div className="empty_clusters">
                            <div className="empty_clusters_content">
                                <img src={IconEmpty} alt="empty"/>
                                <p className="gt_pt gt_th">
                                    За выбранный вами интервал нет кластеров для отображения.
                                    Пожалуйста, измените параметры фильтрации
                                </p>
                            </div>
                        </div>
                }

            </div>


            {
                /*
                loadingClusters ?
                    <div className="clusters_loading">
                        <LoadSpinner />
                    </div> :

            */
            }
        </div>
    )
}

export default ClustersComponent;
