const SET_CLUSTERS = "SET_CLUSTERS"
const ADD_CLUSTERS = "ADD_CLUSTERS"
const SET_CURRENT_CLUSTER = "SET_CURRENT_CLUSTER"
const SET_PETITION = "SET_PETITION"
const SET_GROUPS = "SET_GROUPS"
const ADD_PETITION = "ADD_PETITION"
const SET_CHART_DATA = "SET_CHART_DATA"
const SET_LOADING_CLUSTERS = "SET_LOADING_CLUSTERS"
const SET_LOADING_PETITIONS = "SET_LOADING_PETITIONS"
const SET_LOADING_GROUPS = "SET_LOADING_GROUPS"
const SET_LOADING_CHARTS = "SET_LOADING_CHARTS"
const SET_LOADING_SCROLL_CLUSTERS = "SET_LOADING_SCROLL_CLUSTERS"
const SET_LOADING_SCROLL_PETITIONS = "SET_LOADING_SCROLL_PETITIONS"




const initialState = {
    clusters: [],
    currentCluster: null,
    petitions : [],
    groups : [],
    chartData:[],
    loadingClusters:false,
    loadingPetitions:false,
    loadingGroups:false,
    loadingCharts:false,
    loadingScrollClusters:false,
    loadingScrollPetitions:false,
}

export const smartReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLUSTERS:
            return { ...state, clusters: action.payload }
        case ADD_CLUSTERS:
            return { ...state, clusters: [...state.clusters,...action.payload] }
        case ADD_PETITION:
            return { ...state, petitions: [...state.petitions,...action.payload] }
        case SET_CURRENT_CLUSTER:
            return { ...state, currentCluster: action.payload }
        case SET_PETITION:
            return { ...state, petitions: action.payload }
        case SET_GROUPS:
            return { ...state, groups: action.payload }
        case SET_CHART_DATA:
            return { ...state, chartData: action.payload }
        case SET_LOADING_CLUSTERS:
            return { ...state, loadingClusters: action.payload }
        case SET_LOADING_PETITIONS:
            return { ...state, loadingPetitions: action.payload }
        case SET_LOADING_GROUPS:
            return { ...state, loadingGroups: action.payload }
        case SET_LOADING_CHARTS:
            return { ...state, loadingCharts: action.payload }
        case SET_LOADING_SCROLL_CLUSTERS:
            return { ...state, loadingScrollClusters: action.payload }
        case SET_LOADING_SCROLL_PETITIONS:
            return { ...state, loadingScrollPetitions: action.payload }
        default:
            return state
    }
}


export const setClustersAction = (clusters) => ({ type: SET_CLUSTERS, payload: clusters })
export const addClustersAction = (clusters) => ({ type: ADD_CLUSTERS, payload: clusters })
export const addPetitionAction = (petition) => ({ type: ADD_PETITION, payload: petition })
export const setCurrentClusterAction = (cluster) => ({ type: SET_CURRENT_CLUSTER, payload: cluster })
export const setPetitionAction = (petition) => ({ type: SET_PETITION, payload: petition })
export const setGroupsAction = (groups) => ({ type: SET_GROUPS, payload: groups })
export const setChartDataAction = (data) => ({ type: SET_CHART_DATA, payload: data })
export const setLoadingClustersAction = (data) => ({ type: SET_LOADING_CLUSTERS, payload: data })
export const setLoadingPetitionsAction = (data) => ({ type: SET_LOADING_PETITIONS, payload: data })
export const setLoadingGroupsAction = (data) => ({ type: SET_LOADING_GROUPS, payload: data })
export const setLoadingChartsAction = (data) => ({ type: SET_LOADING_CHARTS, payload: data })
export const setLoadingScrollClustersAction = (data) => ({ type: SET_LOADING_SCROLL_CLUSTERS, payload: data })
export const setLoadingScrollPetitionsAction = (data) => ({ type: SET_LOADING_SCROLL_PETITIONS, payload: data })

