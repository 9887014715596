
import "./PipelineComponent.scss"

import BachIcon from "../../assets/icons/batch_processing.svg"
import SmartReportIcon from "../../assets/icons/smart_reports.svg"
import AnswerIcon from "../../assets/icons/answer.svg"
import {useNavigate} from "react-router-dom";


function PipelineComponent() {

    const navigate = useNavigate();

    return (
        <div className="pipeline">
            <p className="gt_h4">Потоковая обработка данных</p>
            <div className="pipeline_row">
                <button className="pipeline_item" type="button" onClick={()=> navigate("/bach_processing")}>
                    <img src={BachIcon} alt="bach processing"/>
                    <hr width="100%" color="#E4ECFD" size="1"/>
                    <p className="gt_f6" style={{textAlign: "start"}}>Пакетная обработка</p>
                </button>
                <button className="pipeline_item" type="button"  onClick={()=> navigate("/smart_reports")}>
                    <img src={SmartReportIcon} alt="smart reports"/>
                    <hr width="100%" color="#E4ECFD" size="1"/>
                    <p className="gt_f6" style={{textAlign: "start"}}>Диагностический интерфейс автоматизированной
                        кластеризации и суммаризации</p>
                </button>
                <button className="pipeline_item" type="button" onClick={()=> navigate("/binary_classifier")}>
                    <img src={AnswerIcon} alt="answer"/>
                    <hr width="100%" color="#E4ECFD" size="1"/>
                    <p className="gt_f6" style={{textAlign: "start"}}>Диагностический интерфейс бинарной классификации</p>
                </button>
            </div>
        </div>
    )
}

export default PipelineComponent;
