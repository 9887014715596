import "./FoivMapComponent.scss"
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getClustersAction} from "../../../store/actions/smartReportAdction";
import {setMapStyle} from "../helpers";
import MapButtomAg from "../../../assets/icons/map_buttom_ag.svg"
import MapButtomSt from "../../../assets/icons/map_buttom_st.svg"
import MapButtomAp from "../../../assets/icons/map_buttom_ap.svg"
import IconEmpty from "../../../assets/icons/empty_image.svg";
import EmptyMapComponent from "../EmptyMapComponent/EmptyMapComponent";

function FoivMapComponent({sortShow,setShowReport,setGroupBy,setTitle}) {

    const {groups} = useSelector(store=>store.smartReport)
    const dispatch = useDispatch()

    useEffect(()=>{

        groups.forEach(group => {
            let el = document.getElementById(group.id)
            if (el){
                setMapStyle(el,group.le,sortShow)
            }
        })

    },[groups,sortShow])

    function handleClick(item){
        setShowReport(true)
        let settings = JSON.parse(localStorage.getItem("settings_cluster"))
        settings.size = 15
        settings.filter_map = item.sn
        settings.group_by = "foiv"
        setGroupBy("ОГВ")
        dispatch(getClustersAction(settings,false,true))

        localStorage.setItem("map_settings",JSON.stringify({
            type:"foiv",
            name: item.sn
        }));

        setTitle(item.sn)
    }

    return (
        <div className="foiv_map">
            <p className="gt_h4">ОГВ</p>
            {
                groups.filter(gr => gr.le > 0).length > 0 ?
                    <>
                        <div className="parent">
                            {
                                groups.map((item) => (
                                    <button key={item.id.toString()} className="foiv_div" id={item.id.toString()}
                                            onClick={() => handleClick(item)}>
                                        <p style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap"
                                        }}>{item.sn}</p>
                                    </button>
                                ))
                            }
                        </div>
                        <img src={
                            sortShow === "аномальный рост" ? MapButtomAg :
                                sortShow === "стабилизация" ? MapButtomSt : MapButtomAp
                        } alt="footer"/>
                    </> :
                    <EmptyMapComponent />
            }

        </div>
    )
}

export default FoivMapComponent;
