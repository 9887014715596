
import "./CustomTooltipComponent.scss"
import {getDateTime} from "../helpers";

const CustomTooltipComponent = ({ active, payload, label }) => {


    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="gt_pt">{getDateTime(payload[0].payload.td)}</p>
                <p className="gt_pt" style={{color: "#3968C9"}}>
                    Кол-во обращ.: {payload[0].payload.mc}
                </p>
                <p className="gt_pt" style={{color: "#E11432"}}>
                    Коэффициент: {Number.isInteger(payload[0].payload.k) ? payload[0].payload.k : payload[0].payload.k.toFixed(2)} %
                </p>
            </div>
        );
    }

    return null;
};

export default CustomTooltipComponent;
