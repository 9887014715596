import "./BinaryClassifier.scss"
import BackIcon from "../../../assets/icons/back_ar.svg";
import UselessIcon from "../../../assets/icons/useless.svg";
import UsefullIcon from "../../../assets/icons/usefull.svg";
import FormalIcon from "../../../assets/icons/formal.svg";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import TextAreaGosteh from "../../TextAreaGosteh/TextAreaGosteh";
import {getBinaryClissificationAction} from "../../../store/actions/pipelinesAction";
import {useDispatch, useSelector} from "react-redux";
import {setBinaryClassifierResAction} from "../../../store/reducers/batchProcessingReducer";


function BinaryClassifier() {
    const navigate = useNavigate();
    const {binaryClassifierRes} = useSelector(store=>store.batchProcessing)
    const dispatch = useDispatch()


    const [question,setQuestion] = useState("");
    const [answer,setAnswer] = useState("");

    function handleCheck(){
        if (question && answer){
            dispatch(getBinaryClissificationAction(question,answer));
            //setAnswer("")
            //setQuestion("")
        }
    }

    function handleChangePetition(val){
        setQuestion(val);
        dispatch(setBinaryClassifierResAction(""))
    }
    function handleChangeAnswer(val){
        setAnswer(val);
        dispatch(setBinaryClassifierResAction(""))
    }

    return (
        <div className="bach_processing">
            <div className="bach_processing_content">
                <button type="button" className="back_btn" onClick={() => navigate("/pipeline")}>
                    <img src={BackIcon} alt="back"/>
                    <p className="gt_pt gt_gb">Назад</p>
                </button>
                <p className="gt_f3">Бинарный классификатор текста</p>
                <div className="bach_settings binary_classifier">
                    <div className="bach_settings_item ">
                        <TextAreaGosteh value={question} setValue={handleChangePetition} label="Вопрос" count={65000} rows={4}/>
                    </div>
                    <div className="bach_settings_item ">
                        <TextAreaGosteh value={answer} setValue={handleChangeAnswer} label="Ответ" count={65000} rows={4}/>
                    </div>
                    <div className="btn_block">
                        <button type="button" className="gt_btn_primary start_btn" style={{width:"226px"}}
                                disabled={!question || !answer}
                                onClick={handleCheck}>
                            <p className="gt_pt">Проверить</p>
                        </button>
                    </div>
                    {binaryClassifierRes ?
                        <div style={{display:"flex", flexDirection:"column",gap:"24px",width:"100%"}}>
                            <hr width="100%" color="#E4ECFD" size="1"/>
                            <div className="response_result">
                                <img style={{padding:"16.25px 21.45px 7.8px 14.3px"}}
                                    src={
                                    binaryClassifierRes === "useful" ? UsefullIcon :
                                        binaryClassifierRes === "formal" ?  FormalIcon:
                                            UselessIcon}
                                     alt="status"/>
                                <p className="gt_h3">
                                    {binaryClassifierRes === "useful" ? "Содержательный ответ" :
                                        binaryClassifierRes === "formal" ?  "Вынужденная отписка" :
                                            "Отписка"}
                                </p>
                            </div>
                        </div> : ""}
                </div>

            </div>
        </div>
    )
}

export default BinaryClassifier;
