import "./TextAreaGosteh.scss"


function TextAreaGosteh({label,value,setValue,count=255,rows=2}) {

    function handleChange(event) {
        if (event.target.value.length <= count){
            setValue(event.target.value)
        }
    }

    return(
        <>
            <label className="gt_ps">
                {label}
            </label>
            <div className="text_area">
                            <textarea id="answer" rows={rows} value={value}
                                      onChange={(e)=>handleChange(e)}/>
                <p className="gt_ps textarea_count">{value.length}/{count}</p>
            </div>
        </>
    )
}

export default TextAreaGosteh
