

export const ISODateString = (d,per) => {
    function pad(n) {return n<10 ? '0'+n : n}
    let time = per === "from" ? "00:00:00Z" : "23:59:59Z";
    return d.getUTCFullYear()+'-'
        + pad(d.getMonth()+1)+'-'
        + pad(d.getDate())+'T'
        + time
}

export const getDateTime = (d) =>{
    function pad(n) {return n<10 ? '0'+n : n}
    const date = new Date(d)
    return pad(date.getDate()) + "."
        + pad(date.getMonth()+1)+'.'
        + pad(date.getFullYear()) + " "
        + pad(date.getHours())+ ":" + pad(date.getMinutes())
}

export const setMapStyle = (element,le,sortShow) => {

    const color = sortShow === "аномальный рост" ? "red" :
        sortShow === "стабилизация" ? "blue" : "orange"

    let add_class = (le > 0 && le <= 20 ) ? color+20 :
            (le > 20 && le <= 40 ) ? color+40 :
                (le > 40 && le <= 60 ) ? color+60 :
                    (le > 60 && le <= 100 ) ? color+80 : "color_disable"


    let new_classname = ""
    element.className.split(' ').forEach(item =>{
        if (item.includes("div")) {
            new_classname = item
        }
    })
    element.className = new_classname + " " + add_class

    let color_code = add_class.includes("red") ? "rgb(162, 48, 66," :
        add_class.includes("blue") ? "rgb(36, 49, 115," :
            "rgb(237, 81, 2,"

    element.style.backgroundColor = ""
    if (add_class !== "color_disable"){
        const oppacity = (le+10)/100
        element.style.backgroundColor = color_code + oppacity+")"
    }
}

export const getClustersRequestSettings =  (period_start,period_end,only_new,groupBy,sortBy,sortDir,page,size) => {
    return {
        "dt_from": period_start,
        "dt_to": period_end,
        "only_new": only_new,
        "group_by": groupBy === "регионы" ? "region": groupBy === "ОГВ" ? "foiv" : "all",
        "sort_by": sortBy === "За период" ? "period" : sortBy === "Доля от всех" ? "fraction" : "relation",
        "sort_dir": sortDir ? "desc" : "asc",
        "token": "9769b4ec-9b96-4d84-9c7d-8b8c96e0a1da-token",
        "filter_map":"",
        "page": page,
        "size": size
    }
}

export const getPetitionsRequestSettings =  (period_start,period_end,only_new,groupBy,cluster_id,page,size) => {
    return {
        "dt_from":period_start,
        "dt_to": period_end,
        "only_new": only_new,
        "group_by": groupBy === "регионы" ? "region": groupBy === "ОГВ" ? "foiv" : "all",
        "id": cluster_id,
        "page": page,
        "size": size,
        "token": "string",

    }
}
