const SET_PROCESSING_TYPE = "SET_PROCESSING_TYPE"
const SET_BINARY_CLASSIFIERS_RESULT = "SET_BINARY_CLASSIFIERS_RESULT"
const SET_PROCESSING_RESULT = "SET_PROCESSING_RESULT"





const initialState = {
    processingType : "",
    binaryClassifierRes:"",
    processingResult : "",

}

export const batchProcessingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROCESSING_TYPE:
            return { ...state, processingType: action.payload }
        case SET_BINARY_CLASSIFIERS_RESULT:
            return { ...state, binaryClassifierRes: action.payload }
        case SET_PROCESSING_RESULT:
            return { ...state, processingResult: action.payload }
        default:
            return state
    }
}


export const setProcessingTypeAction = (type) => ({ type: SET_PROCESSING_TYPE, payload: type })
export const setBinaryClassifierResAction = (res) => ({ type: SET_BINARY_CLASSIFIERS_RESULT, payload: res })
export const setProcessingResultAction = (result) => ({ type: SET_PROCESSING_RESULT, payload: result })


