const SET_USER_INFO = "SET_USER_INFO"
const UPDATE_TOKEN = "UPDATE_TOKEN"
const SET_ERROR_LOCAL_AUTH = "SET_ERROR_LOCAL_AUTH"
const SET_REFRESH_PROCESS = "SET_REFRESH_PROCESS"


const user_info = JSON.parse(localStorage.getItem("user_info"))

localStorage.removeItem("refresh_request")

const initialState = {
    userInfo: user_info,
    errorLocalAuth: false,
    refreshProcess:false
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            return { ...state, userInfo: action.payload }
        case UPDATE_TOKEN:
            return { ...state,userInfo: {...state.userInfo,access_token : action.payload}}
        case SET_ERROR_LOCAL_AUTH:
            return { ...state, errorLocalAuth: action.payload }
        case SET_REFRESH_PROCESS:
            return { ...state, refreshProcess: action.payload }
        default:
            return state
    }
}

export const setUserInfoAction = (user) => ({ type: SET_USER_INFO,payload: user })
export const updateAccessTokenAction = (access_token) => ({ type: UPDATE_TOKEN,payload: access_token })
export const setErrorLocalAuth = (param) => ({ type: SET_ERROR_LOCAL_AUTH,payload: param })
export const setRefreshProcess = (data) => ({ type: SET_REFRESH_PROCESS,payload: data })


