import "./SortSelect.scss"
import {useState} from "react";

function SortSelect({options,value,selectChange,showOpt,setShowOpt }){



    function handleSelect(option){
        selectChange(option)
        setShowOpt(false)
    }
    return (
        <div style={{position:"absolute"}}>
            <div className="sort_select_list">
                {
                    showOpt && options.filter(opt => opt !== value).map((opt, i) => (
                        <div key={opt + i} className="sort_select_row">
                            <div className={`sort_select option_item ${i===0 ? 'first_row' : i===options.length - 2 ? 'last_row': ''}`}>
                                <button type="button" className="sort_select_option"
                                        onClick={() => handleSelect(opt)}>
                                    <p className="gt_ps">{opt}</p>
                                </button>
                            </div>
                            <div className="filter_hr">
                                {i === options.length - 2 ? "" : <hr width="85%" color="#E1E1E1" size="1"/>}
                            </div>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SortSelect;
