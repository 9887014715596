import "./EmptyMapComponent.scss"
import IconEmpty from "../../../assets/icons/empty_map.svg";

function EmptyMapComponent() {
    return (
        <div className="empty_map">
            <div className="empty_map_content">
                <img src={IconEmpty} alt="empty"/>
                <p className="gt_pt gt_th">
                    За выбранный вами интервал нет данных для отображения.
                    Пожалуйста, измените параметры фильтрации
                </p>
            </div>
        </div>
    )
}

export default EmptyMapComponent;
