
import "./ClustersMap.scss"
import {useEffect, useState} from "react";
import RegionsMapComponent from "../RegionsMapComponent/RegionsMapComponent";
import FoivMapComponent from "../FoivMapComponent/FoivMapComponent";
import {useDispatch, useSelector} from "react-redux";
import {getGroupedAction} from "../../../store/actions/smartReportAdction";
import InfoIcon from "../../../assets/icons/b36.svg";
import ModalInfoComponent from "../ModalInfoComponent/ModalInfoComponent";
import LoadSpinner from "../../LoadSpinner/LoadSpinner";


function ClustersMap({period,setShowReport,setGroupBy,checkedNew,groupBy,setTitle}) {
    const dispatch = useDispatch()
    const {loadingGroups} = useSelector(store=>store.smartReport)
    const [showInfo,setShowInfo] = useState(false);


    const [sortShow,setSortShow] = useState("аномальный рост");

    useEffect(()=>{

        if (period.length > 0){
            let settings = {
                "dt_from": period[0],
                "dt_to": period[1],
                "only_new": checkedNew,
                "group_by": groupBy === "регионы" ? "region" : "foiv",
                "show": sortShow === "аномальный рост" ? "ag" : sortShow === "стабилизация" ? "st" : "ap",
                "token": "9769b4ec-9b96-4d84-9c7d-8b8c96e0a1da-token"
            }

            dispatch(getGroupedAction(settings))

            localStorage.setItem("settings_maps",JSON.stringify(settings));
        }


    },[groupBy,sortShow,period,checkedNew])

    return (
        <div className="clusters_map">

            <div className="clusters_filters">
                <div className="clusters_filters_item">
                    <div className="select_filter ">
                        <button type="button"
                                className={`select_item ${sortShow === "аномальный рост" ? 'selected' : ''} `}
                                onClick={() => setSortShow("аномальный рост")}
                                style={{width:"160px"}}
                        >
                            <p className={sortShow === "аномальный рост" ? 'gt_pb' : 'gt_ps'}>Аномальный рост</p>
                        </button>
                        <button type="button"
                                className={`select_item ${sortShow === "стабилизация" ? 'selected' : ''} `}
                                onClick={() => setSortShow("стабилизация")}
                                style={{width:"150px"}}
                        >
                            <p className={sortShow === "стабилизация" ? 'gt_pb' : 'gt_ps'}>Стабилизация</p>
                        </button>
                        <button type="button"
                                className={`select_item ${sortShow === "обращаемость" ? 'selected' : ''} `}
                                onClick={() => setSortShow("обращаемость")}
                                style={{width:"150px"}}
                        >
                            <p className={sortShow === "обращаемость" ? 'gt_pb' : 'gt_ps'}>Обращаемость</p>
                        </button>
                    </div>

                    <button type="button" onClick={() => setShowInfo(true)}>
                        <img src={InfoIcon} alt="info"/>
                    </button>

                </div>
            </div>
            <div style={{position:"relative"}}>
                {
                    loadingGroups ?
                        <div className="loader_conteiner">
                            <LoadSpinner />
                        </div> : " "
                }
                {
                    groupBy === "регионы" ?
                        <RegionsMapComponent sortShow={sortShow} setShowReport={setShowReport} setGroupBy={setGroupBy} setTitle={setTitle}/> :
                        <FoivMapComponent sortShow={sortShow} setShowReport={setShowReport} setGroupBy={setGroupBy} setTitle={setTitle}/>
                }
            </div>

            {
                showInfo ? <ModalInfoComponent setShowInfo={setShowInfo}/> : ""
            }
        </div>
    )
}

export default ClustersMap;
