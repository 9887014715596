
import "./ProcessingStats.scss"
import BackIcon from "../../../assets/icons/back_ar.svg";
import SaveIcon from "../../../assets/icons/k08.svg";
import UseIcon from "../../../assets/icons/k09.svg";
import {useNavigate} from "react-router-dom";
import { PieChart, Pie, Cell } from 'recharts';
import {useEffect, useState} from "react";
import {downloadLastReportAction} from "../../../store/actions/pipelinesAction";
import {useDispatch} from "react-redux";


function ProcessingStats() {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const COLORS = ['#DDF5E7', '#FEF0CC', '#FCD9DE'];

    const [processingResult,setProcessingResult] = useState(JSON.parse(localStorage.getItem("stats_param")))
    const [processingType,setProcessingType] = useState(localStorage.getItem("proc_type"))


    const [data,setData] = useState([]);


    useEffect(() => {
        if (processingType === "Бинарный классификатор текста"){
            setData([
                {
                    value: processingResult.useful
                },
                {
                    value: processingResult.formal
                },
                {
                    value: processingResult.useless
                },
            ])
        }else {
            setData([
                {
                    value: processingResult.clusters_create - processingResult.clusters_new_create <= 0 ? 0 : processingResult.clusters_create - processingResult.clusters_new_create
                },
                {
                    value: processingResult.clusters_new_create
                },
            ])
        }
    }, []);

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, outerRadius,fill, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" strokeDasharray="2 2"/>
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Math.round(percent * 100)}%`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(${value})`}
                </text>
            </g>
        );
    };

    return (
        processingResult &&
        <div className="bach_processing">
            <div className="bach_processing_content">
                <button type="button" className="back_btn" onClick={() => navigate("/bach_processing")}>
                    <img src={BackIcon} alt="back"/>
                    <p className="gt_pt gt_gb">Назад</p>
                </button>
                <p className="gt_f3">Пакетная обработка</p>
                <div className="bach_settings" style={{alignItems:"center"}}>
                    <p className="gt_f6">Итоги обработки данных «{processingType}»</p>
                    {processingType === "Бинарный классификатор текста" ?
                        <div className="stats_table">
                            <div className="stats_table_row">
                                <p className="gt_pt"
                                   style={{padding: "16px", width: "364px", borderRight: "1px solid #E4ECFD"}}>Общее
                                    количество загруженных данных</p>
                                <p className="gt_pt" style={{padding: "16px", width: "254px"}}>{processingResult.total}</p>
                            </div>
                            <div className="stats_table_row" style={{borderTop: "1px solid #E4ECFD"}}>
                                <p className="gt_pt"
                                   style={{padding: "16px", width: "364px", borderRight: "1px solid #E4ECFD"}}>Общее
                                    количество обработанных данных</p>
                                <p className="gt_pt" style={{padding: "16px", width: "254px"}}>{processingResult.done}</p>
                            </div>
                        </div> :
                        <div className="stats_table">
                            <div className="stats_table_row">
                                <p className="gt_pt"
                                   style={{padding: "16px", width: "700px", borderRight: "1px solid #E4ECFD"}}>Общее
                                    количество обработанных данных</p>
                                <p className="gt_pt"
                                   style={{padding: "16px", width: "254px"}}>{processingResult.total}</p>
                            </div>
                            <div className="stats_table_row" style={{borderTop: "1px solid #E4ECFD"}}>
                                <p className="gt_pt"
                                   style={{
                                       padding: "16px",
                                       width: "700px",
                                       borderRight: "1px solid #E4ECFD"
                                   }}>Экспортировать результаты обработки на локальный компьютер в формате CSV</p>
                               <button type="button" className="save_btn" onClick={()=>dispatch(downloadLastReportAction())}>
                                    <img src={SaveIcon} alt="save"/>
                                   <p className="gt_pt gt_gb">Сохранить</p>
                               </button>
                            </div>
                            {/*
                             <div className="stats_table_row" style={{borderTop: "1px solid #E4ECFD"}}>
                                <p className="gt_pt"
                                   style={{
                                       padding: "16px",
                                       width: "700px",
                                       borderRight: "1px solid #E4ECFD"
                                   }}>Использоваться полученные результаты при отображении диагностического
                                    интерфейса</p>
                                <button type="button" className="save_btn">
                                    <img src={UseIcon} alt="use"/>
                                    <p className="gt_pt gt_gb">Использовать</p>
                                </button>
                            </div>
                            */}

                        </div>
                    }

                    <div className="pie_block">

                        <PieChart width={800} height={400}>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                fill="#8884d8"
                                dataKey="value"
                                outerRadius={150}
                                label={renderActiveShape}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                ))}
                            </Pie>
                        </PieChart>

                        <div className="legend_pie">
                            <div className="legend_item">
                                <div className="legend_1 color_block"></div>
                                <p className="gt_ps">{processingType === "Бинарный классификатор текста" ?
                                "количество содержательных ответов" : "количество ранее сформированных кластеров"}</p>
                            </div>
                            <div className="legend_item">
                                <div className="legend_2 color_block"></div>
                                <p className="gt_ps">{processingType === "Бинарный классификатор текста" ?
                                    "количество вынужденных отписок" : "количество новых кластеров"}</p>
                            </div>
                            {processingType === "Бинарный классификатор текста" &&
                                <div className="legend_item">
                                    <div className="legend_3 color_block"></div>
                                    <p className="gt_ps">количество отписок</p>
                                </div>
                            }

                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default ProcessingStats;
