import "./LoadSpinner.scss"
import SpinnerGosteh from "../../assets/icons/spin.svg"

function LoadSpinner({withSvg="50px"}) {
    return (
        <div>
            <img className="load-spinner" style={{width:withSvg}} src={SpinnerGosteh} />
        </div>
    )
}

export default LoadSpinner;
